.MuiFormControlLabel-label.Mui-disabled{
    cursor: not-allowed !important;
    pointer-events: all !important;
    color: unset !important;
}
.Mui-disabled.MuiCheckbox-root{
    color: #00000099 !important;
}
.Mui-checked.Mui-disabled {
    color: var(--mainColor) !important;
}