html {
	height: 100%;
	width: 100%;
}

body {
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
	width: 100%;
	--mainColor: #1976d2;
	--highlightColor: #0f4780;
	--lightGray: #e5e5e5;
	--white: #ffffff;
	--mainBackground: #cce2ff;
	--invalidBackground: #f1f2f4;
	--formLabelColor: #033959;
	--fontColorHeaderSubTitle: #445f6f;
	--grayText: #7697ab;
	--disabled: #abaeb0;
	--divider: #dde5ea;
	--success: #06db41;
	--danger: #fb7b6a;
	--warning: #fbd26a;
	--lighterGray: #e3e9ec;
	--orange: #fa8038;
	--darkDivider: #c7cfd4
}

.App {
	height: 100%;
	width: 100%;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: 'Josefin Sans', sans-serif;
}

ul {
	list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */

input[type='number'] {
	-moz-appearance: textfield;
}

.MuiTable-root .MuiTableCell-stickyHeader {
	background-color: var(--mainBackground);
	color: #033959;
}

.MuiTable-root .MuiTableCell-stickyHeader button svg {
	color: #033959;
}

.MuiTableRow-root :hover {
	background-color: #f8f9fa;
	cursor: pointer;
}

.order_arrow {
	color: white;
}

.MuiPaper-root .MuiTablePagination-root {
	background-color: var(--mainBackground);
	color: #033959;
}

.MuiPaper-root .MuiTablePagination-root .MuiTablePagination-selectIcon {
	color: #033959;
}



.MuiTextField-root .MuiSvgIcon-root {
	color: var(--mainColor);
}

.MuiPagination-ul li .Mui-selected {
	background-color: var(--mainColor);
	color: white;
}

.report__content {
	width: 100%;
	margin: 10px 0;
	padding: 0 8px;
}

@media screen and (max-width: 800px) {
	.report__content {
		width: 100%;
		padding: 0 8px;
	}
}

.stripes__one:nth-child(odd) {
	background-color: #f0f0f0;
}

.stripes__two:nth-child(even) {
	background-color: #f0f0f0;
}

@media screen and (max-width: 400px) {
	input {
		font-size: 12px !important;
	}
}

::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
	background: white;
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: #1976d2;
	border-radius: 100px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #7697ab;
}

.css-fa4zf8-MuiButtonBase-root-MuiButton-root,
.css-djh4j0-MuiButtonBase-root-MuiButton-root,
.css-19lvb6y-MuiButtonBase-root-MuiButton-root {
	width: max-content;
}
